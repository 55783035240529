<script setup lang="ts">
import { string, object } from 'yup';
import { useForm } from 'vee-validate';
import Rate2 from '~/assets/svg/rate_2.svg';
import Rate3 from '~/assets/svg/rate_3.svg';
import Rate4 from '~/assets/svg/rate_4.svg';
import Rate5 from '~/assets/svg/rate_5.svg';
import Rate1 from '~/assets/svg/rate_1.svg';

import { ref, reactive } from '#imports';

const rateList = [
  { id: 1, color: 'text-[#ff0000]', hover: 'hover:!text-danger', component: Rate1 },
  { id: 2, color: 'text-[#fe7a7a]', hover: 'hover:!text-[#fe7a7a]', component: Rate2 },
  { id: 3, color: 'text-warning', hover: 'hover:!text-warning', component: Rate3 },
  { id: 4, color: 'text-[#39e43b]', hover: 'hover:!text-[#39e43b]', component: Rate4 },
  { id: 5, color: 'text-[#00a902]', hover: 'hover:!text-success', component: Rate5 },
];

type Rating = {
  rating: number;
  comment: string;
};

interface IProps {
  isMobile?: boolean;
}

interface Emits {
  (e: 'ratingValue', value: Rating): void;
}

defineProps<IProps>();
const emit = defineEmits<Emits>();

const ratingValue = reactive<Rating>({
  rating: 0,
  comment: '',
});
const selectedRate = ref<any>(null);
const commentSchema = ref<any>(
  object({
    comment: string(),
  }),
);

const { handleSubmit } = useForm<{ comment: string }>({
  validationSchema: commentSchema,
});

const onSubmit = handleSubmit((values: { comment: string }) => {
  ratingValue.rating = selectedRate.value.id;
  ratingValue.comment = values.comment;
  emit('ratingValue', ratingValue);
});

const iconSize = (isMobile = false, selected = false) => {
  const sizeMobile = selected ? 50 : 45;
  const sizeDefault = selected ? 57.78 : 51.95;
  return isMobile ? sizeMobile : sizeDefault;
};
</script>
<template>
  <div class="container">
    <form @submit="onSubmit">
      <h1
        class="font-bold"
        :class="[isMobile ? 'text-base w-[164px]' : 'text-extra']"
      >
        {{ $t('csat.csatheader') }}
      </h1>
      <div class="flex mt-20">
        <div
          v-for="rate in rateList"
          :key="rate.id"
          :value="rate.id"
          class="flex flex-col items-center"
          :class="[isMobile ? 'mr-15' : 'mr-20']"
        >
          <ApzButton
            type="default"
            variant="clear"
            class="!p-0"
            @click="selectedRate = rate"
          >
            <component
              :is="rate.component"
              :width="iconSize(isMobile, !!selectedRate && selectedRate.id === rate.id)"
              :height="iconSize(isMobile, !!selectedRate && selectedRate.id === rate.id)"
              :class="[!!selectedRate && selectedRate.id === rate.id ? rate.color : 'text-gray', rate.hover]"
              class="m-0"
            />
          </ApzButton>
          <span
            :class="[!!selectedRate && selectedRate.id === rate.id ? 'font-bold text-gray-darkest' : 'text-gray']"
            class="mt-[10px]"
          >
            {{ rate.id }}
          </span>
        </div>
      </div>
      <div
        v-if="selectedRate && selectedRate.id > 3"
        class="mt-30 mb-15"
      >
        <p
          class="font-bold"
          :class="[isMobile ? 'text-base' : 'text-medium']"
        >
          {{ $t('csat.goodfeedback') }}
        </p>

        <ApzInput
          name="comment"
          type="textarea"
          :wrapper-class="isMobile ? 'mt-15 w-[100%]' : 'mt-15 w-[60%]'"
          :placeholder="$t('csat.sharefeedbackplaceholder')"
          :row="5"
        />
      </div>

      <div
        v-if="isMobile"
        class="fixed bottom-0 bg-white inset-x-0 shadow-sm px-15"
      >
        <ApzButton
          type="primary"
          class="my-15 w-full"
          :class="{ 'bg-gradient-to-r from-primary to-primary-dark': selectedRate }"
          native-type="submit"
          :disabled="!selectedRate"
        >
          {{ !!selectedRate && selectedRate.id > 3 ? $t('global.submit') : $t('result.continue') }}
        </ApzButton>
      </div>
      <ApzButton
        v-else
        type="primary"
        class="mb-20 float-right !px-[34px] !py-[16px] min-w-[127px]"
        native-type="submit"
        :disabled="!selectedRate"
      >
        {{ !!selectedRate && selectedRate.id > 3 ? $t('global.submit') : $t('result.continue') }}
      </ApzButton>
    </form>
  </div>
</template>
